'use strict'

export default class Polyfills {

	init () {
		if (this.ready) return

		this.polyfillElementClosest()
		this.polyfillCustomEvent()
		this.polyfillNodeListForEach()
		this.polyfillChildNodeRemove()

		this.ready = true
	}

	/**
	 * Polyfill for Element.closest() (IE11)
	 *
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/closest}
	 */
	polyfillElementClosest () {
		if (!Element.prototype.matches) {
			Element.prototype.matches =
				Element.prototype.msMatchesSelector ||
				Element.prototype.webkitMatchesSelector
		}

		if (!Element.prototype.closest) {
			Element.prototype.closest = function (s) {
				var el = this

				do {
					if (el.matches(s)) return el
					el = el.parentElement || el.parentNode
				} while (el !== null && el.nodeType === 1)
				return null
			}
		}
	}

	/**
	 * Polyfill for CustomEvent (IE11)
	 *
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill}
	 */
	polyfillCustomEvent () {
		if (typeof window.CustomEvent === 'function') return false
		function CustomEvent (event, params) {
			params = params || { bubbles: false, cancelable: false, detail: null }
			var evt = document.createEvent('CustomEvent')
			evt.initCustomEvent(
				event,
				params.bubbles,
				params.cancelable,
				params.detail
			)
			return evt
		}
		window.CustomEvent = CustomEvent
	}

	/**
	 * Polyfill for NodeList.forEAch (IE11)
	 *
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill}
	 */
	polyfillNodeListForEach () {
		if (window.NodeList && !NodeList.prototype.forEach) {
			NodeList.prototype.forEach = Array.prototype.forEach
		}
	}

	/**
	 * Polyfill for ChildNode.remove (IE11)
	 *
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove}
	 */
	polyfillChildNodeRemove () {
		(function (arr) {
			arr.forEach(function (item) {
				if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
					return
				}
				Object.defineProperty(item, 'remove', {
					configurable: true,
					enumerable: true,
					writable: true,
					value: function remove () {
						if (this.parentNode === null) {
							return
						}
						this.parentNode.removeChild(this)
					},
				})
			})
		})([Element.prototype, CharacterData.prototype, DocumentType.prototype])
	}
}
