<template>
	<template v-if="ready === true">
		<!-- Search form -->
		<form class="benefitSearch__form">
			<!-- Categories -->
			<div
				v-if="props.categories.length"
				class="benefitSearch__categories"
			>
				<h2 class="benefitSearch__label">
					{{ props.dict.choose_category }}
				</h2>
				<div class="benefitSearch__categorySelect benefitSearch__categorySelect--select">
					<select
						v-model="terms.category"
						class="benefitSearch__select"
					>
						<option
							v-for="category of props.categories"
							:key="category.id"
							:value="category.value"
							class="benefitSearch__option"
						>
							{{ category.title }}
						</option>
					</select>
				</div>
				<div class="benefitSearch__categorySelect benefitSearch__categorySelect--radios">
					<div
						v-for="category of props.categories"
						:key="category.id"
						tabindex="0"
						class="badge"
					>
						<input
							v-model="terms.category"
							type="radio"
							:value="category.value"
							:id="`category-${category.id}`"
							class="badge__input sr-only"
							tabindex="-1"
						>
						<label
							:for="`category-${category.id}`"
							class="badge__filter"
							:class="
								['badge__filter',
									terms.category === category.value ? 'badge__filter--selected' : null,
								]"
						>
							{{ category.title }}
						</label>
					</div>
				</div>
			</div>

			<hr
				class="benefitSearch__divider"
			>

			<!-- Tags -->
			<div
				v-if="props.tags.length "
				class="benefitSearch__categories"
			>
				<h2 class="benefitSearch__label">
					{{ props.dict.choose_tag }}
				</h2>
				<div class="benefitSearch__categorySelect benefitSearch__categorySelect--select">
					<select
						v-model="terms.tag"
						class="benefitSearch__select"
					>
						<option
							v-for="tag of props.tags"
							:key="tag.id"
							:value="tag.value"
							class="benefitSearch__option"
						>
							{{ tag.title }}
						</option>
					</select>
				</div>
				<div class="benefitSearch__categorySelect benefitSearch__categorySelect--radios">
					<div
						v-for="tag of props.tags"
						:key="tag.id"
						class="badge"
					>
						<input
							v-model="terms.tag"
							type="radio"
							:value="tag.value"
							:id="`tag-${tag.id}`"
							class="badge__input sr-only"
						>
						<label
							:for="`tag-${tag.id}`"
							:class="[
								'badge__filter',
								terms.tag === tag.value ? 'badge__filter--selected' : null,
							]"
						>
							{{ tag.title }}
						</label>
					</div>
				</div>
			</div>
		</form>
		<!-- Search results -->
		<div
			ref="searchResultsContainer"
			class="benefitSearch__results"
		>
			<!-- Search results -->
			<h2 class="sr-only">
				{{ props.dict.search_results }}
			</h2>

			<template v-if="props.benefits.length">
				<div
					class="benefits__wrapper benefits__wrapper--archive"
					v-if="filteredBenefits[currentPage -1] && filteredBenefits[currentPage -1].length"
				>
					<article
						v-for="item of filteredBenefits[currentPage -1]"
						:key="item.id"
						class="benefits__item-wrapper"
					>
						<div class="benefits__item">
							<a
								class="benefits__link"
								:href="item.url"
								tabindex="-1"
							>
								<component
									:is="item.image ? 'figure' : 'div'"
									class="benefits__image-wrapper"
								>
									<img
										v-if="item.image"
										class="benefits__image"
										:src="item.image.url"
										:height="item.image.height"
										:width="item.image.width"
										alt=""
										loading="lazy"
									>
								</component>
							</a>

							<div class="benefits__content">
								<ul
									class="benefits__category-wrapper"
									v-if="item.categories.length"
								>
									<li
										class="benefits__category"
										v-for="category of item.categories"
										:key="category.id"
									>
										<input
											v-model="terms.category"
											type="radio"
											:value="category.value"
											:id="`category-${category.id}`"
											class="badge__input sr-only"
										>
										<label
											:for="`category-${category.id}`"
											class="badge__label"
											tabindex="0"
										>
											{{ category.title }}
										</label>
									</li>
								</ul>
								<a
									class="benefits__link"
									:href="item.url"
								>
									<h3
										class="benefits__title"
										v-html="item.title"
									/>
								</a>
								<p class="benefits__cutoff-text">
									{{ item.summary }}
								</p>
							</div>
						</div>
					</article>
				</div>

				<!-- No search results -->
				<p
					v-else
					v-html="props.dict.no_search_results"
				/>
			</template>

			<!-- No benefits to show -->
			<p
				v-else
				v-html="props.dict.no_benefits"
			/>
		</div>

		<!-- Pagination -->
		<nav
			v-if="filteredBenefits.length > 1"
			class="pager"
		>
			<h2 class="sr-only">
				{{ props.dict.choose_page }}
			</h2>
			<ul class="pager__list">
				<li
					v-for="pageNum in filteredBenefits.length"
					:key="`page-${pageNum}`"
					class="pager__item"
				>
					<component
						:is="currentPage !== pageNum ? 'button' : 'span'"
						type="button"
						@click="setPage(pageNum, true)"
						:class="[
							'pager__link',
							currentPage === pageNum ? 'pager__current' : null,
						]"
						:aria-selected="currentPage === pageNum"
					>
						<span class="sr-only">{{ props.dict.go_to_page }}</span>
						{{ pageNum }}
						<span class="sr-only">/ {{ filteredBenefits.length }}</span>
					</component>
				</li>
			</ul>
		</nav>
	</template>
</template>

<script setup>

import { ref, computed, onMounted, watch } from 'vue'
import queryString from 'query-string'

const props = defineProps({
	categories: {
		type: [Array],
		required: false,
		default () {
			return []
		},
	},
	tags: {
		type: [Array],
		required: false,
		default () {
			return []
		},
	},
	benefits: {
		type: [Array],
		required: false,
		default () {
			return []
		},
	},
	itemsPerPage: {
		type: [Number],
		required: false,
		default () {
			return 12
		},
		validator (value) {
			return value >= 1
		},
	},
	dict: {
		type: [Object],
		required: false,
		default () {
			return {}
		},
	},
})

// Is app ready to run?
const ready = ref(false)

// Search terms
const terms = ref()

// Filtered benefits
const filteredBenefits = computed(() => {
	if (ready.value === false) return []

	const results = props.benefits.filter(item => {
		// Filter by category
		if (
			terms.value.category &&
			!item.categories.some(category => category.name === terms.value.category)
		) {
			return false
		}

		// Filter by tag
		if (
			terms.value.tag &&
			!item.tags.some(tag => tag.name === terms.value.tag)
		) return false

		return true
	})

	// Split into pages
	return Array.from({ length: Math.ceil(results.length / props.itemsPerPage) }, (v, i) =>
		results.slice(i * props.itemsPerPage, i * props.itemsPerPage + props.itemsPerPage)
	)
})

// Search results container element
const searchResultsContainer = ref(null)

// Update query string to contain search terms and page
const updateQueryString = () => {
	const qs = queryString.stringify({
		...terms.value,
		page: currentPage.value !== 1 ? currentPage.value : null,
	}, {
		skipNull: true,
		skipEmptyString: true,
		arrayFormat: 'bracket',
	})

	window.history.replaceState(
		{},
		'',
		qs.length ? `${window.location.pathname}?${qs}` : window.location.pathname
	)
}

// Current page number
const currentPage = ref(1)

// Set page number and optionally focus to the search results
const setPage = (num = null, focusToResults = false) => {
	if (!num || currentPage.value === num) return

	currentPage.value = num
	updateQueryString()

	// Focus to search results container
	if (focusToResults === true && searchResultsContainer.value) {
		searchResultsContainer.value.focus()
	}
}

onMounted(() => {
	// Parse query string
	const qs = queryString.parse(location.search, {
		arrayFormat: 'bracket',
	})

	// Merge default terms with user defined terms
	terms.value = {
		...{
			category: null,
			tag: null,
		},
		category: qs.category || null,
		tag: qs.tag || null,
	}

	// Set current page
	setPage(qs.page ? parseInt(qs.page) : 1)

	// Mark app as ready to run
	ready.value = true

	// Watch for changes in terms
	watch(() => terms.value, () => {
		updateQueryString()
		setPage(1)
	}, {
		deep: true,
	})
})

</script>
