import '../css/styles.scss'
import Site from '../lib/Site.js'
import jQuery from 'jquery'

import '@fontsource/raleway/latin-100.css'
import '@fontsource/raleway/latin-100-italic.css'
import '@fontsource/raleway/latin-200.css'
import '@fontsource/raleway/latin-200-italic.css'
import '@fontsource/raleway/latin-300.css'
import '@fontsource/raleway/latin-300-italic.css'
import '@fontsource/raleway/latin-400.css'
import '@fontsource/raleway/latin-400-italic.css'
import '@fontsource/raleway/latin-500.css'
import '@fontsource/raleway/latin-500-italic.css'
import '@fontsource/raleway/latin-600.css'
import '@fontsource/raleway/latin-600-italic.css'
import '@fontsource/raleway/latin-700.css'
import '@fontsource/raleway/latin-700-italic.css'
import '@fontsource/raleway/latin-800.css'
import '@fontsource/raleway/latin-800-italic.css'
import '@fontsource/raleway/latin-900.css'
import '@fontsource/raleway/latin-900-italic.css'

if (document.body.id !== 'form-builder-form') {
	window._site = new Site()
	window.jQuery = jQuery
}
