'use strict'

import Polyfills from './Polyfills.js'
import { Bartender } from '@fokke-/bartender.js'
import boringmenu from '@teppokoivula/boringmenu'
import Glightbox from 'glightbox'
import { createApp } from 'vue'
import BenefitSearch from './BenefitSearch.vue'
import Swiper, { Pagination, Navigation, Autoplay } from 'swiper'

/**
 * Site class contains general purpose site-specific features.
 *
 * @version 1.1.0
 */
export default class Site {

	/**
	 * Class constructor
	 *
	 * @param {Object} options Options for the class.
	 */
	constructor (options = {}) {
		this.options = {}

		// Init polyfills
		const polyfills = new Polyfills()
		polyfills.init()

		// Init off-canvas after mobile menu is ready
		document.addEventListener('boringmenu-init-done', () => {
			if (document.body.classList.contains('bartender')) {
				window.bartender = new Bartender({
					debug: false,
					trapFocus: true,
					overlay: true,
				}),
				// Add a new bar
				bartender.addBar('bartenderSidebar', {
					el: '.bartenderSidebar',
					position: 'right',
					mode: 'float',
				})

				// Toggle button
				document.querySelectorAll('.toggleBartenderSidebar').forEach(button => {
					button.addEventListener('click', (event) => {
						// Pass button as second argument to return focus after closing the bar.
						bartender.toggle('bartenderSidebar', event.target)
					})
				})

				// Close button
				document.querySelectorAll('.closeBartenderSidebar').forEach(button => {
					button.addEventListener('click', () => {
						bartender.close()
					})
				})

			}

			window.addEventListener('bartender-open', (e) => {
				// When right bar is opened, focus to search query field
				console.log(e.detail)
				if (e.detail.bar.position == 'right' && e.detail.button.classList.contains('mobile-search-btn')) {
					const searchQueryField = e.detail.bar.element.querySelector(
						'[data-bartender-bar="right"] .searchForm__input'
					)
					if (searchQueryField) searchQueryField.focus()
				}
			})
		})

		// Init mobile menu
		const mobileMenu = document.getElementById('mobile-menu')
		new boringmenu({
			selectors: {
				menu: '.menu-mobile__list--level-1',
			},
			classes: {
				item: 'menu-mobile__item',
				itemActive: 'menu-mobile__item--current',
				itemParent: 'menu-mobile__item--parent',
				toggle: 'menu-mobile__toggle',
				toggleTextContainer: 'sr-only',
			},
			labels: {
				'menu.open': mobileMenu ? mobileMenu.getAttribute('data-labels-open') : 'open',
				'menu.close': mobileMenu ? mobileMenu.getAttribute('data-labels-close') : 'close',
			},
			icons: {
				'menu.open': 'icon-open',
				'menu.close': 'icon-close',
			},
		})

		// Initialize
		this.init(options)
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @param {Object} options Options for the class.
	 * @return {Object}
	 */
	init (options = {}) {

		// Merge user options to the defaults
		this.options = {
			responsiveTables: {
				selector: 'main table',
			},
			imageLinks: {
				parentSelector: 'main',
			},
			...options,
		}

		// Call individual init methods
		this.initResponsiveTables()
		this.initSkipLinks()
		this.initImageLinks()
		this.initBenefitSearch()
		this.initHeroSwiper()
		this.initHeroModal()
		this.initToggleSearch()
		this.initPutiiggiSwiper()
		this.initCountdown()
		this.initAccordion()
		this.initPageChildrenNavCarousels()
		this.initStickyNav()
		this.initUntabbables()
		this.initDepartmentEvents()

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('site-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	/**
	 * Initialize responsive tables
	 *
	 * Finds content tables and wraps them with div.table-wrapper.
	 */
	initResponsiveTables () {
		document.querySelectorAll(this.options.responsiveTables.selector).forEach(table => {
			if (!table.closest('.table-wrapper')) {
				const tableWrapper = document.createElement('div')
				tableWrapper.classList.add('table-wrapper')
				tableWrapper.classList.add('overflow-x-auto')
				table.parentNode.insertBefore(tableWrapper, table)
				tableWrapper.appendChild(table)
			}
		})
	}

	/**
	 * Initialize skip links
	 *
	 * Finds skip links and enhances their behaviour for various screen readers and mobile devices.
	 */
	initSkipLinks () {
		const skipLinks = document.querySelectorAll('.skip-link:not([data-skip-link])')
		if (skipLinks.length) {
			const skipToBlur = event => {
				if (event.target.getAttribute('data-tabindex')) {
					event.target.removeAttribute('tabindex')
					event.target.removeAttribute('data-tabindex')
				}
			}
			skipLinks.forEach(skipLink => {
				skipLink.setAttribute('data-skip-link', true)
				skipLink.addEventListener('click', event => {
					const skipTo = document.getElementById(event.target.href.split('#')[1])
					if (skipTo && skipTo.getAttribute('tabindex') != '-1') {
						event.preventDefault()
						skipTo.setAttribute('tabindex', '-1')
						skipTo.setAttribute('data-tabindex', true)
						skipTo.addEventListener('blur', skipToBlur)
						skipTo.addEventListener('focusout', skipToBlur)
						skipTo.focus()
					}
				})
			})
		}
	}

	/**
	 * Initialize image links
	 */
	initImageLinks () {

		// Parent of image links
		let parentNode = document.querySelector(this.options.imageLinks.parentSelector)
		if (!parentNode) return

		// Add glightbox class to image links
		parentNode.querySelectorAll('a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]').forEach(link => {
			if (!link.classList.contains('glightbox')) {
				let figcaption = link.parentNode.querySelector('figcaption')
				if (!link.getAttribute('data-title') && !link.getAttribute('data-glightbox')) {
					if (figcaption) {
						let caption = figcaption ? figcaption.textContent : ''
						link.setAttribute('data-title', caption)
					}
				}
				link.classList.add('glightbox')
			}
		})

		// Initialize GLightbox
		if (!window.glightbox && parentNode.querySelector('.glightbox')) {
			window.glightbox = Glightbox()
		}
	}

	/**
	 * Mount BenefitSearch Vue components
	 */
	initBenefitSearch () {
		const els = document.querySelectorAll('.benefitSearch')
		if (!els.length) return

		for (const el of els) {
			createApp(BenefitSearch, {
				categories: window.benefitSearchData.categories,
				tags: window.benefitSearchData.tags,
				benefits: window.benefitSearchData.benefits,
				itemsPerPage: window.benefitSearchData.itemsPerPage,
				dict: window.benefitSearchData.dict,
			}).mount(el)
		}
	}

	/**
	 * Init Hero Modal
	 */
	initHeroModal () {
		document.addEventListener('click', event => {
			if (event.target.matches('.glightbox-privacywire')) {
				let priw_storage = (window.localStorage.getItem('privacywire')) ? JSON.parse(window.localStorage.getItem('privacywire')) : ''
				if(priw_storage && (Boolean(priw_storage.cookieGroups.marketing) ?? false)) {
					event.preventDefault()
					let lightbox = Glightbox()
					lightbox.insertSlide({
						href: event.target.href,
						width: '90vw',
					})
					lightbox.open()
				} else {
					event.preventDefault()
					// Open the link in a new tab
					window.open(event.target.href, '_blank')
				}
			}
		})
	}

	/**
	 * Init Hero SwiperJS
	 */
	initHeroSwiper () {
		const swiper = new Swiper('.hero-swiper', {
			modules: [Pagination, Autoplay],
			direction: 'horizontal',
			slidesPerView: 1,
			spaceBetween: 24,
			watchOverflow: true,
			simulateTouch: true,
			rewind: true,
			centeredSlides: true,
			autoplay: {
				delay: 7500,
				disableOnInteraction: false,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		})
	}

	/**
	 * Init Toggle Search
	 */
	initToggleSearch () {
		const button = document.querySelector('.search__icon')

		if (!button) return

		button.addEventListener('click', (e) => {
			e.preventDefault()
		})
	}

	/**
	 * Init Putiiggi SwiperJS
	 */
	initPutiiggiSwiper () {

		const swiper = new Swiper('.putiiggi-swiper', {
			modules: [Navigation, Autoplay],
			direction: 'horizontal',
			slidesPerView: 1,
			spaceBetween: 24,
			watchOverflow: true,
			simulateTouch: true,
			rewind: false,
			loop: true,
			centeredSlides: false,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			pauseOnMouseEnter: true,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 2.5,
					loop: false,
				},
			},
			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
				clickable: true,
			},
		})

		document.addEventListener('focusin', event => {
			if(event.target.matches('.putiiggi-product-highlights__link')) {
				swiper.autoplay.pause()
				document.querySelector('.swiper-button-next').classList.add('!hidden')
				document.querySelector('.swiper-button-prev').classList.add('!hidden')
			}
		})

		document.addEventListener('focusout', event => {
			if(event.target.matches('.putiiggi-product-highlights__link')) {
				swiper.autoplay.start()
				document.querySelector('.swiper-button-next').classList.remove('!hidden')
				document.querySelector('.swiper-button-prev').classList.remove('!hidden')
			}
		})

	}

	/**
	 * Initialize countdown block
	 */
	initCountdown () {
		// bail out early if browser doesn't support IntersectionObserver
		if (!('IntersectionObserver' in window)) return

		const items = document.querySelectorAll('.js-countdown')
		if (!items.length) return

		items.forEach((item) => {
			// store value in data-value and set visible value to zero
			item.setAttribute('data-value', item.textContent)
			item.textContent = 0

			// init intersection observer instance
			const observer = new IntersectionObserver(
				this.animateCountdownItem,
				{
					threshold: 0.5,
				}
			)

			// provide the observer with a target
			observer.observe(item)
		})
	}

	/**
	 * Animate countdown item
	 *
	 * @param {IntersectionObserverEntry[]} entries
	 * @param {IntersectionObserver}
	 */
	animateCountdownItem (entries, observer) {
		entries.forEach((entry) => {
			if (entry.intersectionRatio > 0) {
				entry.target.classList.add('js-countdown-ready')
				let currentValue = parseInt(entry.target.textContent)
				const targetValue = parseInt(
					entry.target.getAttribute('data-value')
				)
				const valueDiff = Math.abs(targetValue - currentValue)
				const increment = Math.ceil(
					(valueDiff /
						parseInt(entry.target.getAttribute('data-delay'))) *
						0.025
				)
				const valueInterval = setInterval(() => {
					entry.target.textContent =
						currentValue > targetValue ? targetValue : currentValue
					if (currentValue >= targetValue) {
						clearInterval(valueInterval)
					}
					currentValue += increment
				}, 25)
				observer.unobserve(entry.target)
			}
		})
	}

	/**
	 *
	 * Init  Accordion
	 */
	initAccordion () {
		const accordionHeaders = document.querySelectorAll(
			'[data-accordion-header]'
		)
		if (!accordionHeaders.length) return

		// Add expand event to accordion buttons
		Array.prototype.forEach.call(accordionHeaders, (accordionHeader) => {
			let target = document.getElementById(
				accordionHeader.getAttribute('aria-controls')
			)
			if (target) {
				accordionHeader.onclick = () => {
					let expanded =
							accordionHeader.getAttribute('aria-expanded') == 'true' || false
					accordionHeader.setAttribute('aria-expanded', !expanded)
					target.classList.toggle('hidden', expanded)
				}
			}
		})
	}

	initPageChildrenNavCarousels () {
		const items = document.querySelectorAll('.page-children-nav--carousel')
		if (!items.length && window.innerWidth < 1440) return

		for (const item of items) {
			const listItems = Array.from(item.querySelectorAll('.page-children-nav__list-item--carousel'))
			const images = Array.from(item.querySelectorAll('.page-children-nav__image-wrapper--carousel'))
			if(!listItems.length || !images.length) continue

			for (const listItem of listItems) {
				listItem.addEventListener('mouseover', () => {
					const imageId = listItem.getAttribute('data-page-id')
					const image = images.find(image => image.getAttribute('data-page-id') === imageId)
					if(!image) return

					// Add active class to the corresponding listItem
					listItems.forEach((listItem) => {
						if (listItem.getAttribute('data-page-id') === imageId) {
							listItem.classList.add('page-children-nav__list-item--active')
						} else {
							listItem.classList.remove('page-children-nav__list-item--active')
						}
					})

					// Remove hidden class from the corresponding image
					images.forEach((image) => {
						if (image.getAttribute('data-page-id') === imageId) {
							image.classList.remove('page-children-nav__image-wrapper--hidden')
						} else {
							image.classList.add('page-children-nav__image-wrapper--hidden')
						}
					})

				})
			}

		}
	}

	initStickyNav () {
		const el = document.querySelector('.stickyNav')
		const contentEl = document.querySelector('.bartender__content')
		const thresholdEl = document.querySelector('#js-scroll-threshold')

		if (!el || !contentEl || !thresholdEl) return

		contentEl.addEventListener('scroll', e => {
			this.evaluateStickyNav(el, contentEl, thresholdEl)
		})

		window.addEventListener('resize', e => {
			this.evaluateStickyNav(el, contentEl, thresholdEl)
		})
	}

	evaluateStickyNav (el, contentEl, thresholdEl) {
		if (!el || !contentEl || !thresholdEl) return

		const thresholdTop = Math.round(thresholdEl.getBoundingClientRect().top + contentEl.scrollTop)

		if (contentEl.scrollTop >= thresholdTop) {
			el.classList.add('stickyNav--visible')
			return
		}

		el.classList.remove('stickyNav--visible')
	}

	initUntabbables () {
		document.querySelectorAll('.js-untabbable').forEach(element => {
			element.querySelectorAll('a').forEach(link => {
				link.setAttribute('tabindex', '-1')
			})
		})
	}

	initDepartmentEvents () {
		// Get the expand button
		const expandButton = document.querySelector('.events__expand-btn')

		// We only want this function to happen in DepartmentPage. Only that has this expandButton.
		if (!expandButton) return

		// Get all events items
		const eventItems = document.querySelectorAll('.events__item')

		if (eventItems.length > 5) {
			// If there are more than 5 items, hide the rest and make the button visible
			for (let i = 5; i < eventItems.length; i++) {
				eventItems[i].classList.add('events__hidden')
				eventItems[i].setAttribute('tabindex', '-1') // Setting tabindex to -1 for hidden items
			}
			expandButton.classList.remove('events__hidden')
			expandButton.classList.add('events__visible')
		} else {
			// If there are less than 3 items, hide the button
			expandButton.classList.add('events__hidden')
		}

		// Add event listener to the checkbox
		expandButton.addEventListener('change', function () {
			if (this.checked) {
				// If checkbox is checked, show all hidden items
				for (let item of eventItems) {
					item.classList.remove('events__hidden')
					item.removeAttribute('tabindex') // Removing tabindex attribute to set it back to normal
				}
				// and hide the expand button.
				expandButton.classList.add('events__hidden')
			}
		})
	}

}
